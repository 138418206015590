<template>
  <div>
    <ed-esocial-lote
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>
import EdEsocialLote from "@/components/esocial/lote/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {
    EdEsocialLote
  },
  mounted() {
  },
  beforeDestroy() {
  },
  provide() {
    return {
    };
  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
