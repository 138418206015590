<template>
  <div class="row mt-4">
    <!--LOGS-->
    <ed-modal v-model="bind.boolExibirLog" width="70%" v-if="formDataCadastro">
      <div slot="title">
        Logs Transmissão - Procotoclo {{ formDataCadastro.strNumeroProtocolo }}
      </div>

      <div class="" slot="content" v-if="bind.boolExibirLog">
        <ed-log :formData="formDataCadastro" />
      </div>
    </ed-modal>

    <!--NOVA TRANSMISSAO-->
    <ed-modal
      v-model="bind.boolExibirCadastro"
      width="50%"
      v-if="formDataCadastro"
    >
      <div slot="title">
        Logs Transmissão - Procotoclo {{ formDataCadastro.strNumeroProtocolo }}
      </div>

      <div class="" slot="content" v-if="bind.boolExibirCadastro">
        <ed-form
          :formData="formDataCadastro"
          :handlerSave="salvar"
          disableFixedSave
        >
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formDataCadastro.strTipoEnvio"
            name="strTipoEnvio"
            label="Tipo de Envio"
            :items="bind.arrayTipoEnvio"
            rules="required"
          />

          <ed-alert class="col-12 pa-3 text-left" type="warning">
            <p>ORIENTAÇÕES</p> 
         
            <p>
              Inclusão: Selecione esta opção na primeira vez que
            enviar o lote ou quando a tentativa de inclusão foi rejeitada.
            </p>

            <p>   Alteração: Selecione esta opção para alterar o evento. Nesse ponto
            deve existir um lote de inclusão enviado.</p>
         
          </ed-alert>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      strModel="Esocial/EsocialEnvio"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @search="getEsocialLoteEnvio"
      @register="onRegister"
      disabledEditRows
      disabledDeleteRows
      disabledSearchRows
      :config="{ registerName: 'Nova Transmissão no E-Social' }"
    >
    </ed-table>
  </div>
</template>

<script>
import {
  EdTable,
  EdModal,
  EdAlert,
  EdInputSelect,
  EdForm,
} from "@/components/common/form";
import EdEsocial from "@/components/esocial/index";
import EdLog from "@/components/esocial/partials/log";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: null,
    },
  },
  components: {
    EdTable,
    EdModal,
    EdForm,
    EdAlert,
    EdInputSelect,
    EdEsocial,
    EdLog,
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  created() {},
  data() {
    return {
      loading: false,
      bind: {
        boolExibirEmail: false,
        boolExibirLog: false,
        boolExibirCadastro: false,
        intTotalFiltrosApliados: 1,
        arrayTipoEnvio: [
          {intId:'INC',strNome:'INCLUSÃO'},
          {intId:'ALT',strNome:'ALTERAÇÃO'}
        ],

      },
      formDataCadastro: {},
      formFiltros: {
        evento: null,
        strEvento: this.strEvento,
        intFuncionarioId: null,
        intCadastroGeralEmpresaId: null,
        arrayDataCadastro: [],
        arrayStatus: [],
      },
      arrayHeaders: [
        {
          text: "Tipo de Envio",
          sortable: true,
          value: "strTipoEnvio",
        },
        {
          text: "Protocolo",
          sortable: true,
          value: "strNumeroProtocolo",
        },
        {
          text: "Resposta",
          sortable: true,
          value: "strResposta",
        },
        {
          text: "Data",
          sortable: true,
          value: "strDataCadastro",
        },
      ],
      arrayRows: [],
      objPagination: null,
    };
  },
  watch: {
    "formData.intId"() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.formData) {
        this.formFiltros.intEsocialLoteId = this.formData.intId;
        this.getEsocialLoteEnvio();
      }
    },

    onRegister() {
      this.formDataCadastro = {
        intId: 0,
        intEsocialLoteId: this.formData.intId,
        strTipoEnvio: 'INC',
      };
      this.bind.boolExibirCadastro = true;
    },

    visualizarLog(data) {
      this.formDataCadastro = Object.assign(data, {});
      this.bind.boolExibirLog = true;
    },

    consultarProtocolo(data){
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Esocial/EsocialEnvio", {intId:data.intId,boolConsultaProtocolo:true})
        .then((objResult) => {

          this.$root.$dialog.loading(false);

          if (objResult.status == 200) {
            this.getEsocialLoteEnvio()
            this.$emit('salvar',{});
          }
        })
    },

    getEsocialLoteEnvio() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Esocial/EsocialEnvio", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModel) => {
              let item = {
                intId: objModel.intId,
                strNumeroProtocolo: objModel.strNumeroProtocolo ? objModel.strNumeroProtocolo : '---',
                strTipoEnvio:objModel.strTipoEnvio == 'INC' ? 'INCLUSÃO' : 'ALTERAÇÃO',
                strResposta:
                  objModel.intResposta + " - " + objModel.strResposta,
                strDataCadastro: this.$utilidade.toDate(
                  objModel.strDataCadastro
                ),
                buttons: [],
                _item: objModel,
              };

              if (objModel.strNumeroProtocolo) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("buscar"),
                  color: "success",
                  title: "Clique para consultar o protocolo",
                  click: this.consultarProtocolo,
                });
              }

              if (objModel.strLog && objModel.strLog.length) {
                item.buttons.push({
                  icone:
                    this.$utilidade.getIcone("risco") + " faa-flash animated",
                  color: "warning",
                  title:
                    "Clique para visualizar os logs da transmissão do arquivo",
                  click: this.visualizarLog,
                });
              }

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Esocial/EsocialEnvio", this.formDataCadastro)
        .then((objResult) => {
          this.$root.$dialog.loading(false);
          if (objResult.status == 200) {
            this.bind.boolExibirCadastro = false;
            this.$emit('salvar',{});
            this.getEsocialLoteEnvio();
          }
        });
    },
  },
};
</script>
