<template>
  <div>
    <!--EMAIL-->
    <ed-modal v-model="bind.boolExibirEmail">
      <div slot="title">
        Enviar Lote por Email
        {{
          formData.intId
            ? " :: Lote " + formData.intId + " :: " + formData.empresa.strNome
            : ""
        }}
      </div>

      <div
        class=""
        slot="content"
        v-if="formData.intId && bind.boolExibirEmail"
      >
        <ed-log-email
          boolDisableEstatistica
          :intPointId="formData.intId"
          strPointType="EsocialLote"
          strTemplate="email.esocial.lote"
          :strAssunto="
            'Envio de Lote E-Social Nº ' +
            formData.intId +
            ' - Evento ' +
            formData.strEvento
          "
        />
      </div>
    </ed-modal>

    <!--TRANSMITIR-->
    <ed-modal v-model="bind.boolExibirTransmitir">
      <div slot="title">
        Transmitir
        {{
          formData.intId
            ? " Lote " + formData.intId + " :: " + formData.empresa.strNome
            : ""
        }}
      </div>

      <div
        class=""
        slot="content"
        v-if="formData.intId && bind.boolExibirTransmitir"
      >
        <ed-esocial-lote-transmitir
          :key="'transmitir-' + $root.$session.versao"
          :formData="formData"
          @salvar="getEsocialLote"
        />
      </div>
    </ed-modal>

    <!--MOVIMNENTO-->
    <ed-modal v-model="bind.boolExibirMovinento">
      <div slot="title">
        Arquivos do
        {{
          formData.intId
            ? " Lote " + formData.intId + " :: " + formData.empresa.strNome
            : ""
        }}
      </div>

      <div
        class=""
        slot="content"
        v-if="formData.intId && bind.boolExibirMovinento"
      >
        <ed-esocial
          :key="'movimento-' + $root.$session.versao"
          :strEvento="formData.strEvento"
          :intEsocialLoteId="formData.intId"
          @delete="getEsocialLote"
        />
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      strModel="Esocial/EsocialLote"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      :intTotalFiltrosApliados="bind.intTotalFiltrosApliados"
      :disabledFilters="false"
      @search="getEsocialLote"
      @filter="getEsocialLote"
      disabledRegisterRows
      disabledEditRows
      disabledDeleteRows
      :config="{ registerName: 'Gerar XML dos Evento' }"
    >
      <template slot="table-filter">
        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          label="Data da Ocorrência"
          name="arrayData"
          v-model="formFiltros.arrayDataCadastro"
          multiple
          clearable
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
          v-model="formFiltros.strEvento"
          label="Evento"
          name="strEvento"
          itemValue="strCodigo"
          :items="bind.arrayEventos"
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formFiltros.intCadastroGeralEmpresaId"
          label="Empresa"
          name="intCadastroGeralEmpresaId"
          item-text="strNome"
          route="Cadastro/CadastroGeral"
          :filters="{ intTipoCadastroGeralId: 58 }"
          clearable
        />

        <!-- <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.intFuncionarioId"
          label="Funcionário"
          name="intFuncionarioId"
          item-text="strNome"
          route="Cadastro/Funcionario"
          :filters="{
            intCadastroGeralEmpresaId: formFiltros.intCadastroGeralEmpresaId,
          }"
          clearable
        /> -->

        <ed-input-tipo
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formFiltros.arrayStatus"
          label="Status"
          name="arrayStatus"
          tipo="1390"
          clearable
          multiple
        />
      </template>
    </ed-table>
  </div>
</template>

<script>
import {
  EdTable,
  EdModal,
  EdInputAutoComplete,
  EdInputTipo,
  EdInputSelect,
  EdInputDate,
} from "@/components/common/form";
import EdLogEmail from "@/components/common/log/email/index";
import EdEsocial from "@/components/esocial/index";
import EdEsocialLoteTransmitir from "@/components/esocial/lote/transmitir";

export default {
  name: "pageCadastroEmpresa",
  props: {
    strEvento: {
      type: [String],
      required: false,
      default: null,
    },
  },
  components: {
    EdTable,
    EdModal,
    EdInputAutoComplete,
    EdInputTipo,
    EdInputSelect,
    EdInputDate,
    EdLogEmail,
    EdEsocial,
    EdEsocialLoteTransmitir,
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  created() {},
  data() {
    return {
      loading: false,
      bind: {
        boolExibirEmail: false,
        boolExibirMovinento: false,
        boolExibirTransmitir: false,
        intTotalFiltrosApliados: 1,
        arrayEventos: [],
      },
      formData: {},
      formFiltros: {
        evento: null,
        strEvento: this.strEvento,
        intFuncionarioId: null,
        intCadastroGeralEmpresaId: null,
        arrayDataCadastro: [],
        arrayStatus: [],
      },
      arrayHeaders: [
        {
          text: "Lote",
          sortable: true,
          value: "intId",
          width: "50px",
        },
        {
          text: "Evento",
          sortable: true,
          value: "strEvento",
        },
        {
          text: "Data",
          sortable: true,
          value: "strDataCadastro",
        },
        {
          text: "Empresa",
          sortable: true,
          value: "strNomeEmpresa",
        },
        {
          text: "Status",
          sortable: true,
          value: "strStatus",
        },
      ],
      arrayRows: [],
      objPagination: null,
    };
  },
  watch: {
    "formFiltros.strEvento"() {},
  },
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Esocial/Esocial", { boolInicializar: true })
        .then((objResult) => {
          this.$root.$dialog.loading(false);

          if (objResult.status == 200) {
            this.bind.arrayEventos = objResult.result.arrayEventos;
            this.getEsocialLote();
          }
        });
    },

    downloadLote(data) {
      var contents = data.strXml;
      var filename = "LOTE_" + data.intId + ".xml";
      //var contentType = "application/xml";

      //console.log('contents',contents)
      //var blob = this.$utilidade.b64toBlob(contents, contentType);

      this.$utilidade.download(contents, filename);
    },

    transmitirLote(data) {
      this.formData = Object.assign(data, {});
      this.bind.boolExibirTransmitir = true;
    },

    emailLote(data) {
      this.formData = Object.assign(data, {});
      this.bind.boolExibirEmail = true;
    },

    movimentoLote(data) {
      this.formData = Object.assign(data, {});
      this.bind.boolExibirMovinento = true;
    },

    getEsocialLote() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Esocial/EsocialLote", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];
            let arrayLote = [];

            objResult.result.data.forEach((objModel) => {
              arrayLote.push(objModel.intId);

              let strObservacao = "";
              let strStatus =
                '<span title="' +
                (objModel.tipo_status.strValor
                  ? objModel.tipo_status.strValor.strObservacao
                  : "") +
                '" style="color:' +
                (objModel.tipo_status.strValor
                  ? objModel.tipo_status.strValor.strCor
                  : "") +
                '">' +
                (objModel.tipo_status.strValor.strIcone
                  ? '<i class="' +
                    objModel.tipo_status.strValor.strIcone +
                    '"></i> '
                  : "") +
                objModel.tipo_status.strNome +
                "</span>";
              let strDataCadastro = objModel.strDataCadastro;

              let item = {
                intId: objModel.intId,
                strEvento: objModel.strEvento,
                strNomeEmpresa:
                  '<a href="cadastro/empresa/editar/' +
                  objModel.intCadastroGeralEmpresaId +
                  '" target="_blank">' +
                  objModel.empresa.strNome +
                  "</a>",
                strStatus: strStatus,
                strObservacao: strObservacao ? strObservacao : "---",
                strDataCadastro: this.$utilidade.toDate(strDataCadastro, true),
                buttons: [],
                _item: objModel,
              };

              if (objModel.strXml) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("download"),
                  color: "success",
                  title: "Clique para baixar o lote",
                  click: this.downloadLote,
                });
              }

              item.buttons.push({
                icone: this.$utilidade.getIcone("upload"),
                color: "warning",
                title: "Clique para transmitir o lote no eSocial",
                click: this.transmitirLote,
              });

              item.buttons.push({
                icone: this.$utilidade.getIcone("email"),
                color: "primary",
                title: "Clique para enviar o lote por email",
                click: this.emailLote,
              });

              item.buttons.push({
                icone: this.$utilidade.getIcone("xml"),
                color: "primary",
                title: "Clique para visualizar os arquivos desse lote",
                click: this.movimentoLote,
              });

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;

            if (
              this.formData.intId &&
              arrayLote.indexOf(this.formData.intId) == -1
            ) {
              this.bind.boolExibirMovinento = false;
            }
          }
        });
    },
  },
};
</script>
